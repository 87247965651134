.ant-input {
    font-size: 14px !important;
}

.ant-btn svg {
    margin: 0 !important;
}

.steps-content {
    margin-top: 20px;
}

.status-table {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    background-color: #c1c1c1;
    border-radius: 6px;
    font-size: 12px;
}

.status-table.Published {
    background-color: #0f9153;
    color: #FFF
}

.delete-action {
    margin-left: 5px;
}

.img-paper-category {
    width: 55px;
}

.add-program .ant-radio-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.add-program .ant-radio-group .ant-radio-button-wrapper {
    height: 200px;
    padding: 20px;
}

.add-program .ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item {
    width: 32%;
    display: flex;
    justify-content: center;
}

.add-program .ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.add-program .ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item span img {
    width: 100%;
    max-width: 140px;
    height: auto;
}

.upload-image .ant-form-item-control-input-content>span {
    height: 40px;
    display: flex;
    width: 100%;
    border: 1px solid #d9d9d9;
}

.upload-image .ant-form-item-control-input-content .ant-btn.ant-btn-default {
    height: 100%;
    border: 0;
}

.upload-image .ant-form-item-control-input-content .ant-btn:hover {
    color:#00C16E; 
}

.upload-image .ant-form-item-control-input-content .ant-btn:focus {
    color:#00C16E;
}

.upload-image .ant-upload-list-text-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-image .ant-upload-list-item-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 355px;
}

.upload-image .ant-upload-list-item {
    margin-top: 0 !important;
    margin-left: 10px;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background-color: #00C16E;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: #00C16E;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
    background: #00C16E;
    border-color: #00C16E;
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-color: #00C16E;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
    color: #00C16E;
    border-color: #00C16E;
}

.ant-steps-item-finish .ant-steps-item-icon {
    color: #00C16E;
    border-color: #00C16E;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-color: #00C16E;
    background-color: rgba(0, 193, 110, 0.1);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    border-color: #00C16E;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
    background-color: #00C16E;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #00C16E;
}

.ant-radio-button.ant-radio-button-checked {
    border-color: #00C16E;
}

.title-paper {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.description-paper {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.modal-paper .left-div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal-antd-paper {
    width: 1000px !important;
}

.img-file-add-paper-div {
    height: 206px;
    width: 100%;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #c1c1c1;
    margin-bottom: 20px;
}

.ant-picker {
    height: 40px;
}

.actions-buttons-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.preview-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
    background-color: #2D2D3F;
    padding: 20px;
}

.preview-div .title-preview {
    color: #FFF;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.4;
}

.preview-div .descricao-preview {
    color: #FFF;
    font-size: 18px;
    line-height: 1.4;
}

.preview-div .author {
    color: #FFF;
}

.preview-div .views-share {
    display: flex;
}

.preview-div .views {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.preview-div .share {
    color: #fff;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.preview-div .share img,
.preview-div .views img {
    margin-right: 5px;
}

.preview-buttons {
    width: 100%;
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
}

.preview-buttons .read-article-button-div {
    width: 70%;
}

.preview-buttons .share-button-div {
    width: 12%;
}

.preview-buttons .favorite-button-div {
    width: 12%;
}

.preview-buttons .read-article-button-div button {
    height: 40px;
    background-color: #00C16E;
    width: 100%;
    color: #FFF !important;
    border-color: #00C16E;
    font-weight: bold;
    border-radius: 6px;
}

.preview-buttons .favorite-button-div button {
    height: 40px;
    background-color: #00C16E;
    width: 100%;
    color: #FFF !important;
    border-color: #00C16E;
    font-weight: bold;
    border-radius: 6px;
}

.preview-buttons .share-button-div button {
    height: 40px;
    background-color: #00C16E;
    width: 100%;
    color: #FFF !important;
    border-color: #00C16E;
    font-weight: bold;
    border-radius: 6px;
}

.img-radio-button {
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.img-radio-button img {
    max-height: 200px;
    height: 100%;
    width: auto;
}

.radio-group-categories .ant-radio-button-wrapper {
    width: 31%;
}
.preview_file  {
  display: none;
}

.preview_file.hasPreview  {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  border-radius: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
}

.preview_file.sponsor.hasPreview  {
  background-size: contain;
}

.preview_file img {
  width: auto;
  height: 90%;
}

.dragger.hasPreview .ant-upload .ant-upload-btn .ant-upload-drag-container p {
  opacity: 0;
}
