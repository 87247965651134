.delete-action {
    margin-left: 5px;
}

.ant-upload .ant-btn {
    border: 0 !important;
    height: 100%;
    box-shadow: none;
}

.add-category .ant-radio-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.add-category .ant-radio-group .ant-radio-button-wrapper {
    height: 200px;
    padding: 20px;
}

.add-category .ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item {
    width: 32%;
    display: flex;
    justify-content: center;
}

.add-category .ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.add-category .ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item span img {
    width: 100%;
    max-width: 140px;
}

.title-category {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.description-category {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.modal-category .left-div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal-antd-category {
    width: 1000px !important;
}

.img-file-add-category-div {
    height: 206px;
    width: 100%;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #c1c1c1;
    margin-bottom: 20px;
}

.ant-picker {
    height: 40px;
}

.actions-buttons-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.preview-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
    background-color: #2D2D3F;
    padding: 20px;
}

.preview-div .title-preview {
    color: #FFF;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.4;
}

.preview-div .descricao-preview {
    color: #FFF;
    font-size: 18px;
    line-height: 1.4;
}

.preview-div .author {
    color: #FFF;
}

.preview-div .views-share {
    display: flex;
}

.preview-div .views {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.preview-div .share {
    color: #fff;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.preview-div .share img,
.preview-div .views img {
    margin-right: 5px;
}

.preview-buttons {
    width: 100%;
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
}

.preview-buttons .read-article-button-div {
    width: 70%;
}

.preview-buttons .share-button-div {
    width: 12%;
}

.preview-buttons .favorite-button-div {
    width: 12%;
}

.preview-buttons .read-article-button-div button {
    height: 40px;
    background-color: #00C16E;
    width: 100%;
    color: #FFF !important;
    border-color: #00C16E;
    font-weight: bold;
    border-radius: 6px;
}

.preview-buttons .favorite-button-div button {
    height: 40px;
    background-color: #00C16E;
    width: 100%;
    color: #FFF !important;
    border-color: #00C16E;
    font-weight: bold;
    border-radius: 6px;
}

.preview-buttons .share-button-div button {
    height: 40px;
    background-color: #00C16E;
    width: 100%;
    color: #FFF !important;
    border-color: #00C16E;
    font-weight: bold;
    border-radius: 6px;
}