body {
  padding-bottom: 0 !important;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
}

.layout-signin {
  background-size: cover !important;
  background-repeat: no-repeat !important;

}

.column-img {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('https://medicalportfolioapp.com/wp-content/uploads/2022/08/banner-img-top-mobile-bg@2x.png');
}