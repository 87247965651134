.loading_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: transparent linear-gradient(180deg, rgb(0, 145, 83) 0%, #FFF 100%) 0% 0% no-repeat padding-box;
}

.loading_div .ant-spin {
  font-size: 30px;
}

.loading_div .ant-spin .ant-spin-dot-item {
  background-color: #FFF;
  font-size: 40px;
}
